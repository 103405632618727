import { calcPercentage } from '../../gameUtils';
import { EventTypes } from '../../global.d';
import { setIsTurboSpin } from '../../gql/cache';
import { eventManager } from '../config';
import { UiButton } from '../ui/uiButton';

class TurboSpinBtn extends UiButton {
  constructor() {
    super(setIsTurboSpin() ? 'turbospin-on' : 'turbospin-off');
    this.interactive = !this.isDisabled;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.TOGGLE_TURBO_SPIN, (_isTurboSpin: boolean) => {
      this.handleUpdateIntent();
    });
  };

  private handleClick = (): void => {
    setIsTurboSpin(!setIsTurboSpin());
    this.handleUpdateIntent();
  };

  private handleUpdateIntent(): void {
    if (setIsTurboSpin()) {
      this.updateIntent('turbospin-on');
    } else {
      this.updateIntent('turbospin-off');
    }
  }

  public override handlePosition(): void {
    this.btn.anchor.set(1, 0);
    let size = calcPercentage(this.applicationSize.width, 7.5);
    const ratio = this.applicationSize.width / this.applicationSize.height;
    const gap = calcPercentage(this.applicationSize.width, 1.25);
    let spinBtnSize = calcPercentage(this.applicationSize.width, 17);
    let x = this.applicationSize.width - gap;
    let y = this.applicationSize.height / 2 + spinBtnSize / 2 + gap;
    this.setSize(size);

    if (!this.isPortraitMode && ratio > 1.9) {
      size = calcPercentage(this.applicationSize.height, 10);
      spinBtnSize = calcPercentage(this.applicationSize.height, 25);
      y = this.applicationSize.height / 2 + spinBtnSize / 2 + gap;
      this.setSize(size);
    }

    if (this.isPortraitMode) {
      spinBtnSize = calcPercentage(this.applicationSize.height, 17);
      size = calcPercentage(this.applicationSize.height, 7.5);
      this.btn.anchor.set(1, 0.5);
      x = this.applicationSize.width / 2 - spinBtnSize / 1.7;
      y = this.applicationSize.height - spinBtnSize * 1.3;
      this.setSize(size);
    }

    this.x = x;
    this.y = y;
  }
}

export default TurboSpinBtn;
