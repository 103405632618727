import i18n from 'i18next';
import _ from 'lodash';
import { DropShadowFilter, DropShadowFilterOptions } from 'pixi-filters';
import { Filter, isMobile, Loader, LoaderResource } from 'pixi.js';
import { ELoaderStages, type ILoaderResource } from '@money.energy/shared-components/dist/loader/d';
import type { IResource } from '@money.energy/shared-components/dist/resources/d';
import { config, ISongs, SlotId } from '../config';
import { buyFeatureBonusesId, EventTypes, FeatureState, GameMode, ISettledBet, ReelSet } from '../global.d';
import { setBetAmount, setCoinValue, setGameError, setSlotConfig } from '../gql/cache';
import Tween from '../slotMachine/animations/tween';
import {
  BASE_WIN_LIMIT,
  BIG_WIN_LIMIT,
  DOUBLE_WIN_LIMIT,
  eventManager,
  MEGA_WIN_LIMIT,
  REELS_AMOUNT,
  SLOTS_PER_REEL_AMOUNT,
  SUPER_WIN_LIMIT,
  WinStages,
} from '../slotMachine/config';
import { Features, Icon } from '../slotMachine/d';

export const waitPromise = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const pixiLoad = (): Promise<Partial<Record<string, LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    Loader.shared.load((_loader, resources) => {
      const failed = _.filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
    Loader.shared.onError.once(() => {
      return reject();
    });
  });
};

export const loadGameAssets = (
  assets: {
    name: string;
    src: string;
  }[],
  baseUrl: string,
): Promise<void> => {
  Loader.shared.baseUrl = baseUrl;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    assets.forEach((asset) => Loader.shared.add(asset.name, asset.src));
    let tries = config.failureRetries;
    let success = false;

    while (tries > 0) {
      try {
        tries -= 1;
        await pixiLoad();
        success = true;
        break;
      } catch (err) {
        console.error(err);
      }
    }

    return success ? resolve() : reject();
  });
};

export const loadImages = async (
  assets: IterableIterator<[string, IResource]>,
  cb?: CallableFunction,
): Promise<void> => {
  let promises: Promise<IResource>[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, value] of assets) {
    promises.push(
      new Promise((resolve, reject) => {
        const asset: HTMLImageElement = new Image();
        asset.src = value.source;
        asset.onload = () => {
          if (cb) cb(value.key);
          resolve(value);
        };
        asset.onerror = () => reject(value);
      }),
    );
  }

  let tries = config.failureRetries;
  let success = false;

  while (tries > 0) {
    try {
      tries -= 1;
      const result = await Promise.allSettled(promises);
      const failed = _.filter(
        result,
        (asset: { status: string }) => asset.status === 'rejected',
      ) as PromiseRejectedResult[];

      if (failed.length) {
        promises = failed.map((rejected) => {
          return new Promise((resolve, reject) => {
            const asset: HTMLImageElement = new Image();
            asset.src = (rejected.reason as { source: string; key: string }).source as string;
            asset.onload = () => {
              if (cb) cb((rejected.reason as { source: string; key: string }).key);
              resolve(rejected.reason);
            };
            asset.onerror = () => reject(rejected.reason);
          });
        });
        continue;
      }
      success = true;
      break;
    } catch (err) {
      console.error(err);
    }
  }

  return success ? Promise.resolve() : Promise.reject();
};

export const isDevelopmentEnv = (): boolean => process.env.NODE_ENV === 'development';

export const isTesting = (): boolean => {
  return window.location.host.includes('testing');
};

export const getCssVar = (cssVar: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(cssVar);
};

export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (width < height) {
    return height * (parseInt(getCssVar('--footer-height-portrait'), 10) / 100);
  }
  return height * (parseInt(getCssVar('--footer-height-landscape'), 10) / 100);
};

export const getFromMappedSymbol = <T>(map: Record<SlotId, { default: T; freespin?: T }>, id: SlotId): T =>
  map[id as SlotId].default;

export const normalizeBalance = (balance = 0): number => {
  return balance / 100;
};

export const normalizeCoins = (coins = 0): number => {
  return (coins * setCoinValue()) / 100;
};

export const showCurrency = (currency: string): boolean => {
  return currency !== 'FUN';
};

export const loadErrorHandler = (error?: Error, resources?: ILoaderResource[]): void => {
  const stage = resources?.find((r) => !!r.error);
  const errorMsg = stage?.error as unknown as string;
  switch (stage?.name) {
    case ELoaderStages.AUTH:
      setGameError({
        show: true,
        type: 'network',
        message:
          (i18n.t(['errors.CLIENT.INVALID_CLIENT_TOKEN', 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
      break;
    default:
      setGameError({
        show: true,
        type: 'network',
        message:
          (i18n.t([errorMsg === 'Failed to fetch' ? 'errors.UNKNOWN.NETWORK' : 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
  }
};

export const isBuyFeatureEnabled = (features: Features[] = []): boolean => {
  const freeSpinFeature = features.find((i) => i.id === 'freeSpins');

  return freeSpinFeature?.enabled || false;
};

const normalizeLayout = (coord: number, layout: string[]) => {
  return coord < 0 ? layout.length - (Math.abs(coord) % layout.length) : coord % layout.length;
};

export const getSpinResult = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Icon[];
}): Icon[] => {
  const cols = REELS_AMOUNT;
  const rows = SLOTS_PER_REEL_AMOUNT;
  const spinResult = [...(Array(cols * rows) as Icon[])].map((_, index) => {
    const row = Math.floor(index / cols);
    const column = index % cols;
    const layout = reelSet.layout[column as number];

    const initialCoord = reelPositions[column as number];
    const coord = (initialCoord as number) + row - 1;
    return (
      icons.find((icon) => icon.id === reelSet.layout[column as number]![normalizeLayout(coord, layout as string[])]) ||
      icons[0]
    );
  }) as Icon[];

  return spinResult;
};

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key as string]: value,
    };
  }, {});
  return param as T;
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      typeof (window.navigator as unknown as { standalone: unknown }).standalone !== 'undefined')
  );
};

export const getBetResult = (betResult: ISettledBet | null): ISettledBet => {
  if (betResult === null) throw new Error('Invalid bet result');
  return betResult;
};

export const normalizePosition = (size: number, pos: number): number => {
  while (pos < 0) pos += size;
  return pos % size;
};

export const getBonusIdByFeature = (featureState: FeatureState): string => {
  return buyFeatureBonusesId[featureState as FeatureState]!;
};

export const isFreeSpinMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS;
};

export const isBuyFeatureMode = (mode: GameMode): boolean => {
  return mode === GameMode.BUY_FEATURE;
};

export const getWinStage = (winAmount: number): WinStages => {
  const betAmount = normalizeCoins(setBetAmount());
  const multiplier = normalizeCoins(winAmount) / betAmount;

  if (multiplier < DOUBLE_WIN_LIMIT) {
    return WinStages.None;
  }
  if (multiplier >= DOUBLE_WIN_LIMIT && multiplier < BASE_WIN_LIMIT) {
    return WinStages.BaseWin;
  }
  if (multiplier >= BASE_WIN_LIMIT && multiplier < BIG_WIN_LIMIT) {
    return WinStages.BigWin;
  }
  if (multiplier >= BIG_WIN_LIMIT && multiplier < MEGA_WIN_LIMIT) return WinStages.MegaWin;
  if (multiplier >= MEGA_WIN_LIMIT && multiplier < SUPER_WIN_LIMIT) return WinStages.SuperWin;
  return WinStages.UltraWin;
};

export const nextTick = (callback: () => void): void => {
  setImmediate(callback);
};
export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.lines || setSlotConfig().lines.length)) / 100;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  sessionStorage.setItem('positions', btoa(positions));
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotStopped,
  isPopupOpened,
  isAutoPlay,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isPopupOpened: boolean;
  isAutoPlay: boolean;
}): boolean => {
  if (isBuyFeatureMode(gameMode) && isFreeSpinsWin) {
    return false;
  }
  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isPopupOpened) {
    return false;
  }

  if (isAutoPlay) {
    return false;
  }

  return true;
};

export const dropShadowFilter = (options: Partial<DropShadowFilterOptions>): Filter => {
  return new DropShadowFilter(options) as Filter;
};

export const delayedAction = (delay: number, completeCallback: () => void, startCallback?: () => void): void => {
  const delayAnim = Tween.createDelayAnimation(delay);
  if (startCallback) {
    delayAnim.addOnStart(startCallback);
  }
  delayAnim.addOnComplete(completeCallback);
  delayAnim.start();
};

export const getRandomBagMultiplier: () => number = () => {
  const multipliers = [1, 2, 3, 4, 5, 7, 10, 15, 20, 25, 50, 100];
  return multipliers[Math.floor(Math.random() * multipliers.length)] as number;
};

export const getBGMSoundByGameMode = (mode: GameMode): ISongs => {
  switch (mode) {
    case GameMode.BASE_GAME:
      return ISongs.Background;
    // case GameMode.FREE_SPINS:
    //   return ISongs.Background;
    default:
      return ISongs.Background;
  }
};

export const getSlotOrderBySlotId = (slotId: SlotId): number => {
  switch (slotId) {
    case SlotId.SC1:
      return 12;
    case SlotId.WL:
      return 11;
    case SlotId.F:
      return 10;
    case SlotId.G:
      return 9;
    case SlotId.H:
      return 8;
    case SlotId.I:
      return 7;
    case SlotId.J:
      return 6;
    case SlotId.A:
      return 5;
    case SlotId.B:
      return 4;
    case SlotId.C:
      return 3;
    case SlotId.D:
      return 2;
    case SlotId.E:
      return 1;
    default:
      return 0;
  }
};

export const getLayerOrderByName = (name: string): number => {
  switch (name) {
    case 'Background':
      return 8;
    case 'SafeArea':
      return 9;
    case 'Backdrop':
      return 10;
    case 'BigWinContainer':
      return 11;
    case 'ControlBtn':
      return 9;
    case 'CloseBtn':
      return 10;
    default:
      return 0;
  }
};

export const fallBackReelPosition = () => {
  eventManager.emit(EventTypes.ROLLBACK_REELS);
};

export const isRegularMode = (mode: GameMode): boolean => {
  return mode === GameMode.BASE_GAME;
};

export const isTabletPortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && !isLandscape && _deviceWidth >= 768 && _deviceWidth <= 1366;
};
export const isTabletLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && isLandscape && _deviceWidth >= 950 && _deviceHeight < 1200;
};
export const isMobilePortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && !isLandscape && _deviceWidth < 768;
};
export const isMobileLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && isLandscape && _deviceWidth < 950;
};
