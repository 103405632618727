import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import AudioApi from '@money.energy/audio-api';
import AutoPlaySettings from '.';
import { ISongs } from '../../../config';
import { EventTypes, PopupOpeningTypes } from '../../../global.d';
import { setIsOpenAutoplayPopup, setIsPopupOpeningInProgress } from '../../../gql/cache';
import type { IConfig } from '../../../gql/d';
import { slotConfigGql } from '../../../gql/query';
import { eventManager } from '../../../slotMachine/config';
import Button from '../components/button';
import Popup from '../components/popup';

const AutoPlaySettingsMenu: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { data: configData } = useQuery<IConfig>(slotConfigGql);
  const { isOpenAutoplayPopup } = configData!;

  useEffect(() => {
    if (isOpenAutoplayPopup) {
      AudioApi.play({ type: ISongs.UI_ButtonPress, stopImmediately: [ISongs.UI_ButtonClose] });
      eventManager.emit(EventTypes.OPEN_POPUP_BG);
    } else {
      eventManager.emit(EventTypes.CLOSE_POPUP_BG);
    }
    setShowPopup(isOpenAutoplayPopup);
  }, [isOpenAutoplayPopup]);

  useEffect(() => {
    if (showPopup && configData?.isSpinInProgress) {
      setShowPopup(false);
    }
  }, [configData?.isSpinInProgress, showPopup]);

  const handleClose = (directClickToCloseIcon = false) => {
    if (
      setIsPopupOpeningInProgress() &&
      setIsPopupOpeningInProgress() !== PopupOpeningTypes.AUTOPLAY_POPUP &&
      setIsPopupOpeningInProgress() !== PopupOpeningTypes.BUY_FEATURE_POPUP &&
      !directClickToCloseIcon
    )
      return;

    setTimeout(() => {
      if (!AudioApi.isPlaying(ISongs.UI_ButtonPress)) {
        AudioApi.play({ type: ISongs.UI_ButtonClose });
      }
    }, 0);
    setIsOpenAutoplayPopup(false);
  };

  const handleHover = () => {
    AudioApi.play({ type: ISongs.UI_ButtonHover });
  };

  return (
    <Popup id="popup" className="popup" showNow={showPopup} setShowNow={() => handleClose()}>
      <Button intent="close" className="popup__close" onClick={handleClose} onMouseOver={handleHover} />
      <AutoPlaySettings handleClose={() => handleClose(true)} />
    </Popup>
  );
};

export default AutoPlaySettingsMenu;
