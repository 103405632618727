import type React from 'react';
import { useTranslation } from 'react-i18next';
import AutoplayAdvancedSvg from './icons/autoplayAdvancedSvg';
import AutoplayConfirmSvg from './icons/autoplayConfirmSvg';
import AutoplayOnSvg from './icons/autoplayOnSvg';
import BetDecreaseSvg from './icons/betDecreaseSvg';
import BetIncreaseSvg from './icons/betIncreaseSvg';
import HistorySvg from './icons/historySvg';
import InfoSvg from './icons/InfoSvg';
import LobbySvg from './icons/lobbySvg';
import SkipSvg from './icons/skipSvg';
import SoundSvg from './icons/soundSvg';
import SpinSvg from './icons/spinSvg';
import StartSvg from './icons/startSvg';
import TurbospinOffSvg from './icons/turbospinOffSvg';
import styles from './info.module.scss';

const ButtonBehaviors: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className={styles['title']}>{t('infoButtonBehaviorsTitle')}</h1>
      <div className={styles['buttonBehaviors']}>
        <div className={`${styles['buttonBehaviors__item']} ${styles['start']}`}>
          <StartSvg />
          {t('infoButtonBehaviorsStart')}
        </div>
        <div className={`${styles['buttonBehaviors__item']} ${styles['spin']}`}>
          <SpinSvg />
          {t('infoButtonBehaviorsSpin')}
        </div>
        <div className={`${styles['buttonBehaviors__item']} ${styles['stop']}`}>
          <SkipSvg />
          {t('infoButtonBehaviorsStop')}
        </div>
        <div className={styles['buttonBehaviors__item']}>
          <AutoplayOnSvg />
          {t('infoButtonBehaviorsAutoPlay')}
        </div>
        <div className={styles['buttonBehaviors__item']}>
          <TurbospinOffSvg />
          {t('infoButtonBehaviorsTurbo')}
        </div>

        <div className={styles['buttonBehaviors__item']}>
          <BetIncreaseSvg />
          {t('infoButtonBehaviorsBetInc')}
        </div>
        <div className={styles['buttonBehaviors__item']}>
          <BetDecreaseSvg />
          {t('infoButtonBehaviorsBetDec')}
        </div>
        <div className={styles['buttonBehaviors__item']}>
          <SoundSvg />
          {t('infoButtonBehaviorsSound')}
        </div>
        <div className={styles['buttonBehaviors__item']}>
          <InfoSvg />
          {t('infoButtonBehaviorsMenuOpen')}
        </div>
        <div className={styles['buttonBehaviors__item']}>
          <HistorySvg />
          {t('infoButtonBehaviorsMenuHistory')}
        </div>
        <div className={styles['buttonBehaviors__item']}>
          <LobbySvg />
          {t('infoButtonBehaviorsMenuLobby')}
        </div>
      </div>

      <h1 className={styles['title']}>{t('infoButtonBehaviorsAutoPlaySettingsTitle')}</h1>
      <p
        className={`${styles['p']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoButtonBehaviorsAutoPlaySettingsText'),
        }}
      />
      <div className={`${styles['row']} ${styles['center']}`}>
        <div className={styles['buttonBehaviors__item']}>
          <AutoplayConfirmSvg />
          {t('infoButtonBehaviorsAutoPlayStart')}
        </div>
        <div className={styles['buttonBehaviors__item']}>
          <AutoplayAdvancedSvg />
          {t('infoButtonBehaviorsAutoPlayAdvanced')}
        </div>
      </div>
    </>
  );
};

export default ButtonBehaviors;
