import i18n from 'i18next';
import { isMobile, ITextStyle, Sprite, Texture } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { ISongs } from '../../../config';
import { getBonusIdByFeature } from '../../../gameUtils';
import { EventTypes, FeatureState } from '../../../global.d';
import { Logic } from '../../../logic';
import { States } from '../../../logic/config';
import { BaseController } from '../../../logic/controllers/BaseController';
import { ResourceTypes } from '../../../resources.d';
import { SLOT_FEATURES_CONFIG } from '../../../slotFeaturesConfig/slotFeaturesConfig';
import {
  buyFeatureSubTitleStyle,
  buyFeatureTitleStyleB,
  buyFeatureTotalCost,
  totalCostValueTextStyle,
} from '../../buyFeature/textStyles';
import { SpriteButton, SpriteButtonState } from '../../components/SpriteButton';
import { TextField } from '../../components/TextField';
import { eventManager, PopupTypes } from '../../config';
import type { BuyFeatureConfirmProps } from '../../d';
import { Popup } from '../popup';
import { PopupController } from '../PopupController';
import BuyFeaturePopupConfirmBackgroundAnimated from './buyFeaturePopupConfirmBackgroundAnimated';
import BuyFeaturePopupConfirmBackgroundStatic from './buyFeaturePopupConfirmBackgroundStatic';

class BuyFeaturePopupConfirm extends Popup {
  private popupBg!: BuyFeaturePopupConfirmBackgroundStatic | BuyFeaturePopupConfirmBackgroundAnimated;

  private featureState: FeatureState = FeatureState.FREE_SPINS;

  private titleText: TextField;

  private subTitleText: TextField;

  private subTitleBackplate: Sprite;

  private totalCostText: TextField;

  private totalCostValue: TextField;

  private okBtn: Sprite;

  private cancelBtn: Sprite;

  constructor() {
    super();
    this.x = 145;
    this.y = 190;
    this.visible = false;
    this.initBackground();
    this.titleText = this.initTitle();
    this.subTitleText = this.initSubTitle();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.subTitleBackplate = this.initSubTitleBackplate();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.init();
    this.scale.set(0.88);
    eventManager.on(EventTypes.FORCE_CLOSE_BUYFEATURE, () => {
      this.handleClose(true);
    });
  }

  public override show(props: BuyFeatureConfirmProps): void {
    super.show();
    const { featureState, totalCost } = props;
    this.titleText.setText(i18n.t<string>('buyFeatureConfirmTitleFreeSpins'));
    this.subTitleText.setText(i18n.t<string>('buyFeatureConfirmTitleFreeSpins'));
    this.totalCostText.setStyle(
      featureState === FeatureState.FREE_SPINS ? buyFeatureTitleStyleB : buyFeatureTitleStyleB,
    );
    this.totalCostValue.setStyle(
      featureState === FeatureState.FREE_SPINS ? totalCostValueTextStyle : totalCostValueTextStyle,
    );
    this.featureState = featureState;
    this.visible = true;
    this.totalCostValue.text.text = totalCost;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
  }

  public override hide(): void {
    super.hide();
    this.visible = false;
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText.getText());
    this.addChild(this.subTitleBackplate);
    this.addChild(this.subTitleText.getText());
    this.addChild(this.totalCostText.getText());
    this.addChild(this.totalCostValue.getText());
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
  }

  private initBackground(): void {
    this.popupBg = SLOT_FEATURES_CONFIG.REACT_APP_SLOT_IS_ANIMATED_POPUPS
      ? new BuyFeaturePopupConfirmBackgroundAnimated()
      : new BuyFeaturePopupConfirmBackgroundStatic();
  }

  private initTitle(): TextField {
    const title = new TextField(
      i18n.t<string>('buyFeatureConfirmTitleBase'),
      1300,
      300,
      buyFeatureTitleStyleB as Partial<ITextStyle>,
    );
    title.text.y = 35;
    title.text.x = 555;
    title.text.anchor.set(0.5, 0.5);
    return title;
  }

  private initSubTitle(): TextField {
    const subTitle = new TextField(
      i18n.t<string>('buyFeatureConfirmTitleBase'),
      1300,
      300,
      buyFeatureSubTitleStyle as Partial<ITextStyle>,
    );
    subTitle.text.y = 215;
    subTitle.text.x = 555;
    subTitle.text.anchor.set(0.5, 0.5);
    return subTitle;
  }

  private initTotalCostText(): TextField {
    const titleCost = new TextField(
      i18n.t<string>('buyFeatureTotalCost'),
      1300,
      300,
      buyFeatureTitleStyleB as Partial<ITextStyle>,
    );
    titleCost.text.y = 360;
    titleCost.text.x = 555;

    titleCost.text.anchor.set(0.5, 0.5);

    return titleCost;
  }

  private initTotalCostValue(): TextField {
    const totalCostValue = new TextField('0', 500, 100, buyFeatureTotalCost as Partial<ITextStyle>);
    totalCostValue.text.y = 475;
    totalCostValue.text.x = 555;
    totalCostValue.text.anchor.set(0.5, 0.5);
    return totalCostValue;
  }

  private initSubTitleBackplate(): Sprite {
    const input = new Sprite(Texture.from(ResourceTypes.betPlusBtn));
    input.y = 220;
    input.x = 555;
    input.anchor.set(0.5, 0.5);

    return input;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      onHover: () => AudioApi.play({ type: ISongs.UI_ButtonHover }),
      onClick: () => {
        this.handleClose();
        AudioApi.play({ type: ISongs.UI_ButtonClose });
      },
      onTouchStart: () => {
        this.handleClose();
        AudioApi.play({ type: ISongs.UI_ButtonClose });
      },
    });
    cancelBtn.anchor.set(0.5, 0.5);
    cancelBtn.y = 663;
    cancelBtn.x = isMobile.any ? 125 : 157;
    cancelBtn.width = 116;
    cancelBtn.height = 116;
    return cancelBtn;
  }

  private initOkBtn(): Sprite {
    const okBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      onHover: () => AudioApi.play({ type: ISongs.UI_ButtonHover }),
      onClick: () => {
        // AudioApi.play({ type: ISongs.BuyButton });
        this.startBuyFeature();
      },
      onTouchStart: () => {
        // AudioApi.play({ type: ISongs.BuyButton });
        this.startBuyFeature();
      },
    });

    okBtn.anchor.set(0.5, 0.5);
    okBtn.y = 663;
    okBtn.x = isMobile.any ? 985 : 965;
    okBtn.width = 116;
    okBtn.height = 116;
    return okBtn;
  }

  private startBuyFeature(): void {
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, getBonusIdByFeature(this.featureState));
  }

  public handleClose(closeBuyFeature?: boolean): void {
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    if (closeBuyFeature) {
      AudioApi.play({ type: ISongs.UI_ButtonClose });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      Logic.the.changeState(States.IDLE);
      Logic.the.controller = BaseController.the;
      Logic.the.controller.enterController(null);

      PopupController.the.closeCurrentPopup();
    } else {
      PopupController.the.openPopup(PopupTypes.BUY_FEATURE);
    }
  }
}

export default BuyFeaturePopupConfirm;
