import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import AudioApi from '@money.energy/audio-api';
import { ISongs } from '../../../config';
import { EventTypes, PopupOpeningTypes } from '../../../global.d';
import { setIsOpenInfoPopup, setIsPopupOpeningInProgress } from '../../../gql/cache';
import type { IConfig } from '../../../gql/d';
import { slotConfigGql } from '../../../gql/query';
import { eventManager } from '../../../slotMachine/config';
import Button from '../components/button';
import Popup from '../components/popup';
import ButtonBehaviors from './ButtonBehaviors';
import GameRules from './GameRules';
import styles from './info.module.scss';
import PayLines from './PayLines';
import PaytableComponent from './Paytable';

const InfoPopup: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { data: configData } = useQuery<IConfig>(slotConfigGql);
  const { isOpenInfoPopup } = configData!;
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpenInfoPopup) {
      AudioApi.play({ type: ISongs.UI_ButtonPress });
      eventManager.emit(EventTypes.OPEN_POPUP_BG);
    } else {
      eventManager.emit(EventTypes.CLOSE_POPUP_BG);
    }
    setShowPopup(isOpenInfoPopup);
  }, [isOpenInfoPopup]);

  useEffect(() => {
    if (showPopup && configData?.isSpinInProgress) {
      setShowPopup(false);
    }
  }, [configData?.isSpinInProgress, showPopup]);

  const handleClose = (directClickToCloseIcon = false) => {
    if (
      setIsPopupOpeningInProgress() &&
      setIsPopupOpeningInProgress() !== PopupOpeningTypes.AUTOPLAY_POPUP &&
      setIsPopupOpeningInProgress() !== PopupOpeningTypes.BUY_FEATURE_POPUP &&
      !directClickToCloseIcon
    )
      return;

    setTimeout(() => {
      if (!AudioApi.isPlaying(ISongs.UI_ButtonPress)) {
        AudioApi.play({ type: ISongs.UI_ButtonClose });
      }
    }, 0);
    setIsOpenInfoPopup(false);
  };

  const handleHover = () => {
    AudioApi.play({ type: ISongs.UI_ButtonHover });
  };

  return (
    <Popup id="popup" className="popup" showNow={showPopup} setShowNow={() => handleClose()}>
      <Button onMouseOver={handleHover} intent="close" className="popup__close" onClick={handleClose} />
      <div className={styles['info']}>
        <div className={styles['info__title']}>
          <span>{t('infoTitle')}</span>
        </div>
        <div className={styles['container']}>
          <div className={styles['wrap']}>
            <PaytableComponent />
            <GameRules />
            <PayLines />
            <ButtonBehaviors />
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default InfoPopup;
