import { Container, Graphics } from 'pixi.js';
import { isMobileLandscape, isMobilePortrait, isTabletLandscape, isTabletPortrait } from '../../gameUtils';
import { EventTypes, GameMode } from '../../global.d';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager, SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../config';
import type { IGameContainer } from '../d';
import Frame from '../frame/frame';
import Logo from '../logo/logo';
import type { ReelsContainer } from '../reels/reelsContainer/reelsContainer';
import type WinCountUpMessage from '../winAnimations/winCountUpMessage';

class GameView extends ViewContainer {
  public winSlotsContainer: Container;

  public miniPayTableContainer: Container;

  public reelsBackgroundContainer: Container;

  public reelsContainer: ReelsContainer & ViewContainer;

  public tintContainer: Container;

  public slotsContainer: Container;

  public winCountUpMessage: WinCountUpMessage;

  public frame: Frame;

  public gameLogo: Logo;

  public maskArea: Graphics;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.slotsContainer = new Container();
    this.slotsContainer.name = 'slotsContainer';
    this.slotsContainer.x = -SLOTS_CONTAINER_WIDTH / 2;
    this.slotsContainer.y = -SLOTS_CONTAINER_HEIGHT / 2;
    this.maskArea = new Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();

    this.slotsContainer.interactive = true;
    this.gameLogo = new Logo();
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.winSlotsContainer.x = this.slotsContainer.x;
    this.tintContainer = props.tintContainer;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.miniPayTableContainer.x = this.slotsContainer.x;
    this.miniPayTableContainer.y = this.slotsContainer.y;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.addChild(this.reelsBackgroundContainer);
    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.maskArea);
    this.slotsContainer.mask = this.maskArea;
    this.addChild(this.slotsContainer);
    this.frame = new Frame();
    this.addChild(this.frame);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.gameLogo);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.winCountUpMessage);
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      default:
        this.handleBaseModeChanges();
        break;
    }
  }

  private handleBaseModeChanges(): void {
    // handleBaseModeChanges
  }

  private resizeGameContainer(width: number, height: number, deviceWidth: number, deviceHeight: number): void {
    this.scale.set(width / 1750);
    this.y = 0;

    if (isTabletLandscape(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1750);
    }
    if (isTabletPortrait(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1510);
      this.y = -height * 0.25;
    }
    if (isMobilePortrait(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1470);
      this.y = -height * 0.3;
    }
    if (isMobileLandscape(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1750);
    }
    this.x = width / 2;
  }
}

export default GameView;
