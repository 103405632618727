import type { States } from '../config';

export abstract class State {
  public abstract name: States;

  public abstract nodes: Map<States, State>;

  public abstract enterState(prevState: States): void;
  public abstract exitState(nextState: States): void;
}
