import { States } from '../config';
import { Logic } from '../index';
import { State } from './State';

export class Transition extends State {
  public name: States = States.TRANSITION;

  public nodes: Map<States, State> = new Map();

  public static the = new Transition();

  private constructor() {
    super();
  }

  public enterState(prevState: States): void {
    Logic.the.controller.enterTransitionState(prevState);
  }

  public exitState(nextState: States): void {
    Logic.the.controller.exitTransitionState(nextState);
  }
}
