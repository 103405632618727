import { gql } from '@apollo/client';

export const getBetSettingsGql = gql`
  fragment betSettings on Slot {
    clientSettings {
      coinAmounts {
        default
        quick
      }
    }
    lineSets {
      coinAmountMultiplier
    }
  }
`;
