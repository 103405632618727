import { States } from '../config';
import { Logic } from '../index';
import { State } from './State';

export class Intro extends State {
  public name: States = States.INTRO;

  public nodes: Map<States, State> = new Map();

  public static the = new Intro();

  private constructor() {
    super();
  }

  public enterState(prevState: States): void {
    Logic.the.controller.enterIntroState(prevState);
  }

  public exitState(nextState: States): void {
    Logic.the.controller.exitIntroState(nextState);
  }
}
