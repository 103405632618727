import { States } from '../config';
import { Logic } from '../index';
import { State } from './State';

export class BeforeWin extends State {
  public name: States = States.BEFORE_WIN;

  public nodes: Map<States, State> = new Map();

  public static the = new BeforeWin();

  private constructor() {
    super();
  }

  public enterState(prevState: States): void {
    Logic.the.controller.enterBeforeWinState(prevState);
  }

  public exitState(nextState: States): void {
    Logic.the.controller.exitBeforeWinState(nextState);
  }
}
