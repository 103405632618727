import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQuery, useReactiveVar } from '@apollo/client';
import { cashierUrlAvailable, goToCashier } from '@money.energy/utils-fe';
import { setGameError, setIsRevokeThrowingError, setIsTimeoutErrorMessage } from '../../gql/cache';
import type { IGameError } from '../../gql/d';
import { gameErrorGql } from '../../gql/query';
import Popup from '../UI/components/popup';
import styles from './gameError.module.scss';

const defaultGameError: IGameError = {
  show: false,
  type: 'none',
  message: '',
};

const getMessageTypeError = (t: TFunction<'translation'>, message: string) => {
  if (message) {
    return message;
  }
  return t('errors.UNKNOWN.UNKNOWN');
};

const GameError: React.FC = () => {
  const { data } = useQuery<{ gameError: IGameError }>(gameErrorGql);
  const { t } = useTranslation();

  const isTimeoutError = useReactiveVar(setIsTimeoutErrorMessage);

  const btn = React.useMemo(() => {
    if (data?.gameError.type === 'balance') {
      return (
        cashierUrlAvailable() && (
          <button className={`${styles['btn']} btn`} onClick={goToCashier}>
            {t('cashier')}
          </button>
        )
      );
    }

    return null;
  }, [data?.gameError, t]);

  if (!data) {
    return null;
  }

  const { message } = data.gameError;

  return (
    <div className={data.gameError.show ? styles['backdrop']! : ''}>
      <Popup id="gameError" className="popup" showNow={data.gameError.show} setShowNow={() => null}>
        <div className={styles['gameError']!}>
          <div className={styles['center']!}>
            <p className={styles['title']!}>{getMessageTypeError(t, message)}</p>
            <div className={styles['flex']!}>
              {btn}
              {!isTimeoutError && (
                <button
                  className={`${styles['btn']} btn`}
                  onClick={() => {
                    setGameError(defaultGameError);
                    setIsRevokeThrowingError(false);
                  }}
                >
                  {t('close')}
                </button>
              )}
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default GameError;
