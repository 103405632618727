import type React from 'react';
// import { useTranslation } from 'react-i18next';
import HistoryComponent from './HistoryComponent';

const History: React.FC<{
  setType: React.Dispatch<React.SetStateAction<'menu' | 'info' | 'settings' | 'history'>>;
  show: boolean;
}> = ({ show }) => {
  // const { t } = useTranslation();

  return <>{show && <HistoryComponent />}</>;
};

export default History;
