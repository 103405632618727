import AudioApi from '@money.energy/audio-api';
import { calcPercentage } from '../../gameUtils';
import { EventTypes } from '../../global.d';
import { setIsSoundOn } from '../../gql/cache';
import { eventManager } from '../config';
import { UiButton } from '../ui/uiButton';

class SoundBtn extends UiButton {
  constructor() {
    super(setIsSoundOn() ? 'sound-on' : 'sound-off');
    this.addChild(this.btn);
    this.interactive = true;
    this.initEvents();
    this.initSubscriptions();

    if (!AudioApi.isInitialized) {
      this.setDisable(true);
    }
  }

  private initEvents(): void {
    this.btn.on('click', () => this.handleClick());
    this.btn.on('touchstart', () => this.handleClick());
  }

  private initSubscriptions(): void {
    eventManager.on(EventTypes.TOGGLE_SOUND, (isSoundOn: boolean) => {
      this.updateIntent(isSoundOn ? 'sound-on' : 'sound-off');
    });

    eventManager.on(EventTypes.SOUND_INITIALIZED, () => {
      this.setDisable(false);
    });
  }

  private handleClick(): void {
    const nextState = !setIsSoundOn();
    AudioApi.unSuspend();
    AudioApi.setSoundState(nextState);
    setIsSoundOn(nextState);

    this.updateIntent(nextState ? 'sound-on' : 'sound-off');
  }

  public override handlePosition(): void {
    this.btn.anchor.set(0, 0);
    let size = calcPercentage(this.applicationSize.width, 7.5);
    const gap = calcPercentage(this.applicationSize.width, 1.25);
    const x = gap;
    const y = gap;

    this.setSize(size);

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 7.5);
      this.setSize(size);
    }

    this.x = x;
    this.y = y;
  }
}

export default SoundBtn;
