import type React from 'react';

const SkipSvg = () => {
  return (
    <svg width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_ii_2339_26606)">
        <circle cx="48.5" cy="48" r="48" fill="#FFA800" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4229 45.7463L33.1676 28.6414C31.2862 27.1245 28.5 28.468 28.5 30.8951V65.1049C28.5 67.532 31.2862 68.8755 33.1676 67.3586L54.4229 50.2537C55.859 49.098 55.859 46.902 54.4229 45.7463ZM63.5 28C61.2909 28 59.5 29.7909 59.5 32V64C59.5 66.2091 61.2909 68 63.5 68C65.7091 68 67.5 66.2091 67.5 64V32C67.5 29.7909 65.7091 28 63.5 28Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_ii_2339_26606"
          x="0.5"
          y="0"
          width="96"
          height="96"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2339_26606" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_innerShadow_2339_26606" result="effect2_innerShadow_2339_26606" />
        </filter>
      </defs>
    </svg>
  );
};

export default SkipSvg;
