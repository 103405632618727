import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import Button from '../button';
import type { IPropsPopup, TButtonMenu, TMenuPopup } from './d';
import styles from './popup.module.scss';

export const onClickBtn =
  (dispatch: React.Dispatch<React.SetStateAction<boolean>>, fn?: () => void | null) => (): void => {
    if (fn) {
      fn();
    }
    dispatch(false);
  };

export const ButtonMenu: React.FC<TButtonMenu & TMenuPopup> = (props) => {
  const { cancel, accept, classNameButtonMenu = '', setShowPopup } = props;
  return (
    <div className={classNameButtonMenu}>
      {accept && (
        <Button className={accept.className as string} onClick={onClickBtn(setShowPopup, accept.onClick)}>
          {accept.name}
        </Button>
      )}
      {cancel && (
        <Button className={cancel.className as string} onClick={onClickBtn(setShowPopup, cancel.onClick)}>
          {cancel.name}
        </Button>
      )}
    </div>
  );
};

export const MenuPopup: React.FC<TMenuPopup & TButtonMenu> = (props) => {
  const { children, className = '', position = 'bottom', ...otherProps } = props;
  const isNone = position === 'none';
  const isTop = position === 'top';
  const isBottom = position === 'bottom';

  return (
    <div className={className}>
      {!isNone && isTop && <ButtonMenu {...otherProps} />}
      {children}
      {!isNone && isBottom && <ButtonMenu {...otherProps} />}
    </div>
  );
};

const Popup: React.FC<IPropsPopup & TButtonMenu> = (props) => {
  const { id, children, classNamePortal = '', showNow, setShowNow, ...otherProps } = props;
  const portalDiv = useRef<HTMLElement>(document.getElementById(id));

  useEffect(() => {
    if (portalDiv.current) {
      portalDiv.current.setAttribute('style', styles['popup-root'] + classNamePortal);
    }
  }, [classNamePortal]);

  useEffect(() => {
    const handleClick = (e: Event) => {
      if (portalDiv.current && !portalDiv.current.contains(e.target as Node)) {
        e.preventDefault();
        e.stopPropagation();
        setShowNow(false);
      }
    };

    if (showNow) {
      document.addEventListener('click', handleClick);
      document.addEventListener('touchstart', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
        document.removeEventListener('touchstart', handleClick);
      };
    }
    return () => {};
  }, [showNow, setShowNow]);

  if (!portalDiv.current || !showNow) {
    return null;
  }

  return createPortal(
    <MenuPopup {...otherProps} setShowPopup={setShowNow}>
      {children}
    </MenuPopup>,
    portalDiv.current,
  );
};

export default Popup;
