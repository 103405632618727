import type React from 'react';

const ConfirmBtnSvg = () => {
  return (
    <svg
      className="svg-image"
      width="129"
      height="96"
      viewBox="0 0 129 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ii_2268_5737)">
        <rect x="0.666504" width="128" height="96" rx="32" fill="#44D400" />
      </g>
      <path
        d="M32.6665 44L56.6665 68L96.6665 28"
        stroke="white"
        strokeWidth="12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_ii_2268_5737"
          x="0.666504"
          y="0"
          width="128"
          height="96"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2268_5737" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_innerShadow_2268_5737" result="effect2_innerShadow_2268_5737" />
        </filter>
      </defs>
    </svg>

    // <svg
    //   className="svg-image"
    //   width="144"
    //   height="144"
    //   viewBox="0 0 144 144"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 8C36.6538 8 8 36.6538 8 72C8 107.346 36.6538 136 72 136Z"
    //     fill="black"
    //     fillOpacity="0.7"
    //   />
    //   <path
    //     className="svg-color"
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M112.277 45.437C114.955 48.1149 114.955 52.4566 112.277 55.1345L69.9913 97.4202C67.3135 100.098 62.9717 100.098 60.2939 97.4202C57.616 94.7423 57.616 90.4006 60.2939 87.7227L102.58 45.437C105.257 42.7591 109.599 42.7591 112.277 45.437Z"
    //     fill="white"
    //   />
    //   <path
    //     className="svg-color"
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M69.9913 97.4202C67.3135 100.098 62.9717 100.098 60.2939 97.4202L35.151 72.2773C32.4731 69.5994 32.4731 65.2577 35.151 62.5799C37.8289 59.902 42.1706 59.902 44.8485 62.5799L69.9913 87.7227C72.6692 90.4006 72.6692 94.7423 69.9913 97.4202Z"
    //     fill="white"
    //   />
    // </svg>
  );
};

export default ConfirmBtnSvg;
