import type React from 'react';

const SettingsBtnSvg = () => {
  return (
    <svg
      className="svg-image"
      width="129"
      height="96"
      viewBox="0 0 129 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ii_2268_15330)">
        <rect x="0.666504" width="128" height="96" rx="32" fill="#464646" />
      </g>
      <path d="M64.6665 20L64.6665 74" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M64.6665 54L64.6665 74" stroke="white" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M88.6665 36L88.6665 74" stroke="white" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M88.6665 20V36" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M40.6665 22L40.6665 74" stroke="white" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="40.6665" cy="26" r="10" fill="white" />
      <circle cx="64.6665" cy="54" r="10" fill="white" />
      <circle cx="88.6665" cy="36" r="10" fill="white" />
      <defs>
        <filter
          id="filter0_ii_2268_15330"
          x="0.666504"
          y="0"
          width="128"
          height="96"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2268_15330" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_innerShadow_2268_15330" result="effect2_innerShadow_2268_15330" />
        </filter>
      </defs>
    </svg>
  );
};

export default SettingsBtnSvg;
