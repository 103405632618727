import { Application, Container, Sprite, Texture } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { ISongs } from '../../config';
import { calcBottomContainerHeight, calcPercentage, getCssVar } from '../../gameUtils';
import { EventTypes } from '../../global.d';
import { setIsProceedToGame, setIsShowSoundToast, setIsSoundLoading, setProgress } from '../../gql/cache';
import i18n from '../../i18next';
import { Logic } from '../../logic';
import { ResourceTypes } from '../../resources.d';
import { TextField } from '../components/TextField';
import { eventManager, gameNameTextStyle } from '../config';
import SoundBtn from '../controlButtons/soundBtn';
import Clock from '../ui/clock';
import { UiButton } from '../ui/uiButton';
import Carousel from './Carousel';

class IntroScreen {
  private readonly application: Application;

  private container = new Container();

  private static introScreen: IntroScreen;

  public static initIntroScreen = (): void => {
    IntroScreen.introScreen = new IntroScreen(Logic.the.application);
  };

  public static getInstance = (): IntroScreen => IntroScreen.introScreen;

  private background = new Sprite(Texture.from(ResourceTypes.slotBg));

  private backgroundContainer = new Container();

  private playBtn: UiButton;

  private soundBtn: SoundBtn;

  private carousel: Carousel;

  private clock: Clock;

  private gameName: TextField;

  private bindedResize = this.resize.bind(this);

  private constructor(application: Application) {
    const introContents = [{ img: ResourceTypes.introReel1, txt: i18n.t('introTitle') }];
    this.application = application;
    this.background.anchor.set(0.5, 0.5);
    this.backgroundContainer.name = 'this.backgroundContainer';
    this.backgroundContainer.addChild(this.background);
    this.carousel = new Carousel(introContents);
    this.container.name = 'introContainer';
    this.playBtn = this.initPlayBtn();
    this.clock = new Clock();
    this.gameName = this.initGameName();
    this.soundBtn = new SoundBtn();
    this.container.addChild(this.carousel, this.playBtn, this.soundBtn, this.clock, this.gameName.getText());
    this.application.stage.addChild(this.backgroundContainer, this.container);
    eventManager.addListener(EventTypes.RESIZE, this.bindedResize);
  }

  public getApplication(): Application {
    return this.application;
  }

  private initPlayBtn(): UiButton {
    const btn = new UiButton('play');
    btn.btn.anchor.set(1, 0.5);
    btn.interactive = true;

    const clickCallback = () => {
      AudioApi.play({ type: ISongs.UI_ButtonPress });
      setProgress({ ...setProgress(), wasLoaded: true });
      setTimeout(() => {
        this.destroy();
        eventManager.emit(EventTypes.HANDLE_DESTROY_INTRO_SCREEN);
      });
      setTimeout(() => {
        AudioApi.unSuspend();
        AudioApi.processRestriction({
          restricted: false,
          listToPlay: [{ type: ISongs.CasinoAmbiance }],
          beforeSetQueue: () => setIsSoundLoading(true),
          onChangeRestriction: () => setIsShowSoundToast(false),
        });
      });
    };
    btn.on('click', clickCallback);
    btn.on('touchend', clickCallback);

    return btn;
  }

  private initGameName(): TextField {
    const gameName = new TextField('Neon Palace', 250, 100, gameNameTextStyle);
    gameName.text.anchor.set(0, 1);
    gameName.text.position.set(0, 45);
    return gameName;
  }

  private setBgSize = (width: number, height: number): void => {
    this.backgroundContainer.x = width / 2;
    this.backgroundContainer.y = height / 2;

    const bgAspectRatio = this.background.width / this.background.height;
    const aspectRatio = width / height;
    if (bgAspectRatio > aspectRatio) {
      this.backgroundContainer.scale.set(height / this.background.height);
    } else {
      this.backgroundContainer.scale.set(width / this.background.width);
    }
  };

  private resize(width: number, height: number): void {
    this.application.renderer.resize(width, height);
    const isPortraitMode = width < height;
    // const gap = calcPercentage(width, 1.25);
    const playBtnGap = calcPercentage(width, 2);
    this.background.texture = Texture.from(ResourceTypes.slotBg);
    this.setBgSize(width, height);
    const heightWithBottomGap = isPortraitMode
      ? calcPercentage(height, 100 - parseInt(getCssVar('--footer-height-portrait'), 10))
      : calcPercentage(height, 100 - parseInt(getCssVar('--footer-height-landscape'), 10));
    this.playBtn.btn.anchor.set(1, 1);
    this.playBtn.x = width - playBtnGap;
    this.playBtn.y = height - this.playBtn.height / 2;
    this.playBtn.setSize(Math.min(calcPercentage(width, 15), 180));
    this.carousel.setSize(width, height, height - heightWithBottomGap);

    const containerHeight = calcBottomContainerHeight(width, height);
    const mobilePortrait = width < height;

    const padding = calcPercentage(width, 1.25);

    if (mobilePortrait) {
      this.gameName.text.position.set(padding, height - containerHeight * 0.1);
      this.gameName.text.scale.set(containerHeight / 100);
      this.clock.position.set(width - padding, height - containerHeight * 0.1);
      this.clock.scale.set(containerHeight / 100);
    } else {
      this.gameName.text.position.set(padding, height - padding);
      this.gameName.text.scale.set(containerHeight / 100);
      this.clock.position.set(width - padding, height - padding);
      this.clock.scale.set(containerHeight / 100);
    }

    if (isPortraitMode) {
      this.playBtn.setSize(Math.min(calcPercentage(height, 16), 180));
      this.playBtn.btn.anchor.set(0.5, 1);
      this.playBtn.y = height - this.playBtn.height * 1.4;
      this.playBtn.x = width / 2;
    }
  }

  private destroy(): void {
    this.application.stage.removeChild(this.backgroundContainer);
    this.application.stage.removeChild(this.container);
    setIsProceedToGame(true);
    eventManager.removeListener(EventTypes.RESIZE, this.bindedResize);
  }
}

export default IntroScreen;
