import type React from 'react';

const InfoSvg = () => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2340_27883)">
        <circle cx="24.5" cy="24" r="16" stroke="white" strokeWidth="3" />
        <path d="M20.5 22L24.5 22" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.5 32H28.5" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24.5 22V32" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="24.5" cy="15" r="3" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_2340_27883">
          <rect width="48" height="48" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoSvg;
