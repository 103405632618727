import { Group, Layer } from '@pixi/layers';
import i18n from 'i18next';
import { Container, filters, type Application } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { ISongs } from '../config';
import type { SlotId } from '../config/config';
import { getLayerOrderByName } from '../gameUtils';
import { EventTypes, GameMode, UserBonus } from '../global.d';
import {
  setBrokenGame,
  setCurrentReelSetId,
  setGameError,
  setIsDuringBigWinLoop,
  setIsRevokeThrowingError,
  setLastSpinData,
  setReelSets,
  setUserLastBetResult,
} from '../gql/cache';
import { Logic } from '../logic';
import { States } from '../logic/config';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import { BigWinContainer } from './bigWin/bigWinContainer';
import BottomContainer from './bottomBar/bottomBar';
import type { ViewContainer } from './components/ViewContainer';
import { eventManager, PopupTypes, REELS_AMOUNT } from './config';
import AutoplayBtn from './controlButtons/autoplayBtn';
import InfoBtn from './controlButtons/infoBtn';
import MenuBtn from './controlButtons/menuBtn';
import SoundBtn from './controlButtons/soundBtn';
import SpinBtn from './controlButtons/spinBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import type { ISlotData } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import BuyFeaturePopup from './popups/buyFeaturePopup/buyFeaturePopup';
import BuyFeaturePopupConfirm from './popups/buyFeaturePopupConfirm/buyFeaturePopupConfirm';
// import { FreeSpinsEndPopup } from './popups/freeSpinsPopup/freeSpinsEndPopup';
// import { FreeSpinsPopup } from './popups/freeSpinsPopup/freeSpinsPopup';
import { PopupController } from './popups/PopupController';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import BaseReelsContainer from './reels/reelsContainer/baseReelsContainer';
import type { ReelsContainer } from './reels/reelsContainer/reelsContainer';
import type Slot from './reels/slot';
import SafeArea from './safeArea/safeArea';
import TintContainer from './tint/tintContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import { WinSlotsPresentation } from './winAnimations/winSlotsPresentation';

class SlotMachine {
  public isStopped = false;

  public static initSlotMachine = (slotData: ISlotData): void => {
    SlotMachine.slotMachine = new SlotMachine(Logic.the.application, slotData);
  };

  public static the(): SlotMachine {
    return SlotMachine.slotMachine;
  }

  private static slotMachine: SlotMachine;

  private application: Application;

  private reelsContainer!: BaseReelsContainer;

  private miniPayTableContainer!: MiniPayTableContainer;

  public layer: Layer;

  public layersGroup: Group;

  public bottomContainer!: BottomContainer;

  public gameView!: GameView;

  public background!: Background;

  private menuBtn = new MenuBtn();

  private soundBtn = new SoundBtn();

  private turboSpinBtn = new TurboSpinBtn();

  private spinBtn = new SpinBtn();

  private infoBtn = new InfoBtn();

  private autoplayBtn = new AutoplayBtn();

  private safeArea = new SafeArea();

  private constructor(application: Application, slotConfig: ISlotData) {
    this.application = application;
    this.application.stage.sortableChildren = true;
    this.layersGroup = new Group(1, (layer) => {
      layer.zOrder = getLayerOrderByName(layer.name);
    });
    this.layer = new Layer(this.layersGroup);

    this.application.stage.addChild(this.layer);
    this.initSlotMachineListeners();
    this.buildSlotMachine(slotConfig);
    eventManager.on(EventTypes.CLOSE_POPUP_BG, () => {
      this.handleBlur(false);
    });
    eventManager.on(EventTypes.OPEN_POPUP_BG, () => {
      this.handleBlur(true);
    });
  }

  private initSlotMachineListeners(): void {
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      if (!setBrokenGame()) eventManager.emit(EventTypes.GAME_READY);
    });
    eventManager.addListener(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE, this.setCurrentResultMiniPayTable.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, SlotMachine.handleError);
    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      AudioApi.stop({ type: ISongs.Background });
      switch (Logic.the.controller.gameMode) {
        case GameMode.BASE_GAME:
          AudioApi.play({ type: ISongs.Background });
          break;
        // case GameMode.FREE_SPINS:
        //   AudioApi.play({ type: ISongs.Background });
        //   break;
        default:
          AudioApi.play({ type: ISongs.Background });
      }
      if (setIsDuringBigWinLoop()) {
        AudioApi.play({ type: ISongs.BigWin_Loop });
      }
    });
  }

  private buildSlotMachine(slotConfig: ISlotData): void {
    const isLastBetPresent = setUserLastBetResult().id;
    setReelSets(slotConfig.reels);
    const startPosition = isLastBetPresent
      ? setUserLastBetResult().result.reelPositions
      : slotConfig.settings.startPosition;
    setLastSpinData({
      layout: [],
      reelPositions: startPosition,
    });

    const reelSet = isLastBetPresent
      ? slotConfig.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!
      : slotConfig.reels.find((reelSet) => {
          return (reelSet.type as unknown as string) === 'DEFAULT';
        })!;
    this.background = new Background();
    this.background.name = 'Background';
    this.background.parentGroup = this.layersGroup;
    this.reelsContainer = this.getReelsContainer(reelSet.layout, startPosition);
    this.miniPayTableContainer = new MiniPayTableContainer(
      slotConfig.icons,
      this.reelsContainer.getCurrentSpinResult(),
      this.getSlotById.bind(this),
    );
    const bigWinContainer = new BigWinContainer();
    bigWinContainer.name = 'BigWinContainer';
    bigWinContainer.parentGroup = this.layersGroup;
    this.gameView = new GameView({
      winSlotsContainer: this.getWinSlotsContainer(),
      reelsBackgroundContainer: new ReelsBackgroundContainer(),
      reelsContainer: this.reelsContainer as unknown as ReelsContainer & ViewContainer,
      tintContainer: new TintContainer(),
      winCountUpMessage: new WinCountUpMessage(),
      miniPayTableContainer: this.miniPayTableContainer,
    });
    // const freeSpinsPopup = new FreeSpinsPopup();
    // const freeSpinsEndPopup = new FreeSpinsEndPopup();
    // const menuBtn = new MenuBtn();
    this.menuBtn.parentGroup = this.layersGroup;
    // const soundBtn = new SoundBtn();
    this.soundBtn.name = 'ControlBtn';
    this.soundBtn.parentGroup = this.layersGroup;
    // const turboSpinBtn = new TurboSpinBtn();
    this.turboSpinBtn.name = 'ControlBtn';
    this.turboSpinBtn.parentGroup = this.layersGroup;
    // const spinBtn = new SpinBtn();
    this.spinBtn.name = 'ControlBtn';
    this.spinBtn.parentGroup = this.layersGroup;
    // const infoBtn = new InfoBtn();
    this.infoBtn.name = 'ControlBtn';
    this.infoBtn.parentGroup = this.layersGroup;
    // const autoplayBtn = new AutoplayBtn();
    this.autoplayBtn.name = 'ControlBtn';
    this.autoplayBtn.parentGroup = this.layersGroup;
    // const safeArea = new SafeArea();
    this.safeArea.name = 'SafeArea';
    this.safeArea.parentGroup = this.layersGroup;

    setCurrentReelSetId(reelSet.id);

    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
      this.skipWinAnimation();
    });
    this.gameView.on('touchstart', () => {
      eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
      this.skipWinAnimation();
    });

    // this.gameView.addChild(new BuyFeatureBtn());
    // this.gameView.addChild(this.buildBuyFeaturePopupsContainer());

    // PopupController.the.registerPopup(PopupTypes.FREE_SPINS, freeSpinsPopup);
    // PopupController.the.registerPopup(PopupTypes.FREE_SPINS_END, freeSpinsEndPopup);

    this.safeArea.addChild(this.gameView);
    const backdrop = new Backdrop(EventTypes.OPEN_POPUP_BG, EventTypes.CLOSE_POPUP_BG);
    backdrop.name = 'Backdrop';
    backdrop.parentGroup = this.layersGroup;
    this.bottomContainer = new BottomContainer();
    this.application.stage.addChild(
      this.background,
      backdrop,
      this.safeArea,
      this.bottomContainer,
      new FadeArea(),
      this.menuBtn,
      this.soundBtn,
      this.turboSpinBtn,
      this.spinBtn,
      this.infoBtn,
      this.autoplayBtn,
      bigWinContainer,
    );
  }

  private handleBlur(isBlurred: boolean): void {
    const blurFilter = new filters.BlurFilter();
    blurFilter.blur = 15;
    const filter = isBlurred ? [blurFilter] : [];
    this.background.filters = filter;
    this.gameView.filters = filter;
    this.spinBtn.filters = filter;
    this.menuBtn.filters = filter;
    this.infoBtn.filters = filter;
    this.autoplayBtn.filters = filter;
    this.turboSpinBtn.filters = filter;
    this.soundBtn.filters = filter;
  }

  private skipWinAnimation(): void {
    if (Logic.the.state.name === States.AFTER_WIN || Logic.the.state.name === States.IDLE) {
      Logic.the.skipWinAnimation();
    }
  }

  private buildBuyFeaturePopupsContainer(): Container {
    const container = new Container();
    const buyFeaturePopup = new BuyFeaturePopup();
    const buyFeaturePopupConfirm = new BuyFeaturePopupConfirm();
    PopupController.the.registerPopup(PopupTypes.BUY_FEATURE, buyFeaturePopup);
    PopupController.the.registerPopup(PopupTypes.BUY_FEATURE_CONFIRMATION, buyFeaturePopupConfirm);
    container.addChild(buyFeaturePopup, buyFeaturePopupConfirm);

    return container;
  }

  private getReelsContainer(reelSetLayout: SlotId[][], startPosition: number[]) {
    return new BaseReelsContainer(reelSetLayout, startPosition);
  }

  private getWinSlotsContainer() {
    return new WinSlotsPresentation();
  }

  public onBrokenGame(bonus: UserBonus): void {
    eventManager.emit(EventTypes.BROKEN_GAME, bonus);
    eventManager.emit(EventTypes.GAME_READY);
  }

  private static handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setGameError({
        show: true,
        type: 'network',
        message: i18n.t('error_general'),
      });
    }
  }

  public spinSpinAnimation(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_ANIMATION);
    eventManager.emit(EventTypes.START_SPIN_ANIMATION);
  }

  public getSlotAt(x: number, y: number): Slot | undefined {
    return this.reelsContainer.reels[x as number]!['slots'][y as number];
  }

  public getSlotById(id: number): Slot | undefined {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public setCurrentResultMiniPayTable(): void {
    this.miniPayTableContainer.setSpinResult(this.reelsContainer.getCurrentSpinResult());
  }
}

export default SlotMachine;
