import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { formatNumber } from '@money.energy/utils-fe';
import { config } from '../../config';
import { countCoins, normalizeCoins, showCurrency } from '../../gameUtils';
import { setCurrency, setSlotConfig } from '../../gql/cache';
import type { ISlotHistory, ISlotHistoryData } from '../../gql/d';
import { slotHistoryGql } from '../../gql/query';
import Button from '../UI/components/button';
import styles from './history.module.scss';
import LeftSvg from './leftSvg';
import RightSvg from './rightSvg';
import SkipLeftSvg from './skipLeftSvg';
import SkipRightSvg from './skipRightSvg';

const formatHistory = (historyData: ISlotHistoryData['edges']) => {
  const history = _.map(
    _.map(historyData, (elem) => ({
      ...elem.node,
      cursor: elem.cursor,
    })),
    (elem) => {
      const date = _.get(elem, 'createdAt', Date.now());
      const baseCoinAmount = _.get(elem, 'coinAmount', 1);
      const coinAmount = elem.userBonus
        ? _.get(elem, 'userBonus.bonus.coinAmount', 1) * baseCoinAmount
        : baseCoinAmount;
      const coins = countCoins({
        coinValue: _.get(elem, 'coinValue', 1),
        coinAmount,
        lines: setSlotConfig().lineSets[0]!.coinAmountMultiplier,
      });
      const bet = formatNumber({ currency: setCurrency(), value: coins, showCurrency: showCurrency(setCurrency()) });
      const win = formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(elem.result.winCoinAmount),
        showCurrency: showCurrency(setCurrency()),
      });

      return {
        date,
        win,
        bet,
        gameId: elem.id,
        cursor: elem.cursor,
      };
    },
  );

  return _.reverse(history);
};

const renderKey = (item: ISlotHistory, key: string) => {
  if (key === 'date') {
    const dateString = new Date(item[key as keyof ISlotHistory] as string).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      timeZone: 'UTC',
    });
    const formattedDate = dateString.replace(',', '').replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2-$1-$3');
    return formattedDate;
  }

  if (key === 'bet') {
    return item[key as string];
  }

  return item[key as string];
};
const HistoryComponent: React.FC = () => {
  const { head, showBy } = config.historyTable;
  const { t } = useTranslation();
  const [pageAmount, setPageAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const cursorLastRef = useRef('');
  const cursorFirstRef = useRef('');

  const { data: historyData, fetchMore } = useQuery<
    { bets: ISlotHistoryData },
    {
      input: {
        first?: number;
        last?: number;
        before?: string;
        after?: string;
        filter?: { slotId?: string };
      };
    }
  >(slotHistoryGql, {
    variables: {
      input: { last: showBy, filter: { slotId: setSlotConfig().id } },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (historyData?.bets?.edges.length) {
      setPageAmount(Math.ceil(_.get(historyData, 'bets.pageInfo.count', 0) / showBy));
      cursorLastRef.current = historyData.bets.edges[historyData.bets.edges.length - 1]!.cursor;
      cursorFirstRef.current = historyData.bets.edges[0]!.cursor;
    }
  }, [historyData, showBy]);

  if (!historyData?.bets) {
    return (
      <div className={styles['history']}>
        <div className={styles['spinner']} />
      </div>
    );
  }

  const { pageInfo, edges } = historyData.bets;
  return (
    <div className={styles['history']}>
      <div className={styles['history__title']}>
        <span>{t('gameHistory')}</span>
      </div>
      <div className={styles['history__wrap']}>
        <div className={styles['header']}>
          <div className={`${styles['header-item']} ${styles['date']}`}>{t('date')}</div>
          <div className={`${styles['header-item']} ${styles['bet']}`}>{t('bet')}</div>
          <div className={`${styles['header-item']} ${styles['win']}`}>{t('win')}</div>
        </div>
        <div className={styles['container']}>
          {formatHistory(edges).map((item) => (
            <div key={item.gameId} className={styles['history-item']}>
              {Object.keys(head).map((key) => (
                <div key={key} className={`${styles['history-item__col']} ${styles[key as string]}`}>
                  {key === 'gameId' && t(key)} <span className={styles.value}>{renderKey(item, key)}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
        {pageInfo.count > showBy && (
          <div className={styles['pagination']}>
            <Button
              type="button"
              disabled={currentPage === 1}
              className={styles['pagination__btn'] as string}
              key="first"
              onClick={() => {
                fetchMore({
                  variables: {
                    input: {
                      last: showBy,
                      filter: { slotId: setSlotConfig().id },
                    },
                  },
                }).then(() => {
                  setCurrentPage(1);
                });
              }}
            >
              <SkipLeftSvg />
            </Button>
            <Button
              disabled={currentPage === 1}
              className={`${styles['pagination__btn']}`}
              onClick={() => {
                fetchMore({
                  variables: {
                    input: {
                      first: showBy,
                      after: cursorLastRef.current,
                      filter: { slotId: setSlotConfig().id },
                    },
                  },
                }).then(() => {
                  setCurrentPage(Math.max(1, currentPage - 1));
                });
              }}
            >
              <LeftSvg />
            </Button>
            <Button
              disabled={currentPage === pageAmount}
              className={`${styles['pagination__btn']}`}
              onClick={() => {
                fetchMore({
                  variables: {
                    input: {
                      last: showBy,
                      before: cursorFirstRef.current,
                      filter: { slotId: setSlotConfig().id },
                    },
                  },
                }).then(() => {
                  setCurrentPage(Math.min(pageAmount, currentPage + 1));
                });
              }}
            >
              <RightSvg />
            </Button>
            <Button
              disabled={currentPage === pageAmount}
              className={styles['pagination__btn'] as string}
              onClick={() => {
                fetchMore({
                  variables: {
                    input: {
                      first: ((pageInfo.count - 1) % showBy) + 1,
                      filter: { slotId: setSlotConfig().id },
                    },
                  },
                }).then(() => {
                  setCurrentPage(pageAmount);
                });
              }}
            >
              <SkipRightSvg />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(HistoryComponent);
