import type React from 'react';

const TurbospinOffSvg = () => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_ii_2339_26613)">
        <rect x="0.5" width="48" height="48" rx="16" fill="#44D400" />
      </g>
      <path
        d="M19.5589 8.55984C19.7269 8.21719 20.0752 8 20.4568 8H34.6518C35.4463 8 35.9235 8.88199 35.4888 9.5471L29.1778 19.2029C28.7431 19.868 29.2203 20.75 30.0149 20.75H34.1192C35.0053 20.75 35.4536 21.8173 34.8333 22.4501L18.2203 39.3952C17.5084 40.1215 16.2929 39.4449 16.535 38.4571L19.5299 26.2381C19.6843 25.608 19.2073 25 18.5586 25H13.1039C12.3639 25 11.8803 24.2243 12.206 23.5598L19.5589 8.55984Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_ii_2339_26613"
          x="0.5"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2339_26613" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_innerShadow_2339_26613" result="effect2_innerShadow_2339_26613" />
        </filter>
      </defs>
    </svg>
  );
};

export default TurbospinOffSvg;
