import type React from 'react';

const HistorySvg = () => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24.5" cy="24" r="16" stroke="white" strokeWidth="3" />
      <path d="M23.5 17V25H31.5" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default HistorySvg;
