import type React from 'react';

const LobbyBtnSvg = () => {
  return (
    <svg
      className="svg-image"
      width="97"
      height="96"
      viewBox="0 0 97 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.5 76H24.5V52H16.5L48.5 20L80.5 52H72.5V76Z"
        stroke="white"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LobbyBtnSvg;
