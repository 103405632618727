import type Animation from '../animation';
import type { ISpinAnimation } from '../d';
import { ReelAnimation } from './reelAnimation';

export class BaseAnimation extends ReelAnimation {
  constructor(options: ISpinAnimation) {
    super(options);
    this.appendAnimation(options.disappearingAnimation);
    this.appendAnimation(options.waitingAnimation);
    if (options.appearingAnimation) {
      this.appendAnimation(options.appearingAnimation);
    }
  }

  public getDisappearing(): Animation {
    return this.animations[0] as Animation;
  }

  public getWaiting(): Animation {
    return this.animations[1] as Animation;
  }

  public getAppearing(): Animation {
    return this.animations[2] as Animation;
  }

  public setDisappearing(tween: Animation): void {
    this.animations[0] = tween;
  }

  public setWaiting(tween: Animation): void {
    this.animations[1] = tween;
  }

  public setAppearing(animationGroup: Animation): void {
    this.animations[2] = animationGroup;
  }
}
