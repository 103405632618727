import type React from 'react';

const closeBtnSvg = () => {
  return (
    <svg
      className="svg-image"
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2696_12152)">
        <path
          d="M29.6147 29.6152L66.3843 66.3848"
          stroke="white"
          strokeWidth="8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.6147 66.3848L66.3843 29.6152"
          stroke="white"
          strokeWidth="8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2696_12152">
          <rect width="96" height="96" fill="white" transform="translate(-0.000488281)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default closeBtnSvg;
