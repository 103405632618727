import _ from 'lodash';
import type { TAudioSprite } from '@money.energy/audio-api/dist/d';

export enum ISongs {
  Background = '013_01_Background',
  WinPopup = '013_07_WinPopup',
  BigWin_Loop = '013_08_BigWin_Loop',
  BigWin_End = '013_09_BigWin_End',
  BigWin = '013_10_BigWin',
  MegaWin = '013_11_MegaWin',
  SmallWin = '013_14_SmallWin',
  MediumWin = '013_15_MediumWin',
  HighWin = '013_16_HighWin',
  CountUp_Stop = 'CountUp_Stop',
  CountUp_Loop = 'CountUp_Loop',
  UI_AutoSpin = 'UI_AutoSpin',
  UI_BetChange = 'UI_BetChange',
  UI_ButtonClose = 'UI_ButtonClose',
  UI_ButtonPress = 'UI_ButtonPress',
  UI_ButtonHover = 'UI_ButtonHover',
  UI_MenuOpen = 'UI_MenuOpen',
  UI_SpinStart = 'UI_SpinStart',
  UI_SpinStop = 'UI_SpinStop',
  CasinoAmbiance = 'CasinoAmbiance',
  FeatureTrigger = 'FeatureTrigger',
  ScatterAppear_1 = 'ScatterAppear_1',
  ScatterAppear_2 = 'ScatterAppear_2',
  ScatterAppear_3 = 'ScatterAppear_3',
  ScatterWin = 'ScatterWin',
  UltraWin = 'UltraWin',
  SuperWin = 'SuperWin',
}

export const audioSprite: TAudioSprite = {
  [ISongs.Background]: [0, 148812.33560090704, true],
  [ISongs.BigWin]: [150000, 4015.600907029466],
  [ISongs.BigWin_End]: [156000, 7397.00680272108],
  [ISongs.BigWin_Loop]: [165000, 32310.56689342404, true],
  [ISongs.CasinoAmbiance]: [199000, 38857.14285714286, true],
  [ISongs.CountUp_Loop]: [239000, 449.8866213151871, true],
  [ISongs.CountUp_Stop]: [241000, 2628.208616780057],
  [ISongs.FeatureTrigger]: [245000, 8987.57369614512],
  [ISongs.HighWin]: [255000, 2416.3265306122526],
  [ISongs.MediumWin]: [259000, 2218.956916099785],
  [ISongs.MegaWin]: [263000, 3011.337868480723],
  [ISongs.ScatterAppear_1]: [268000, 2468.5714285714084],
  [ISongs.ScatterAppear_2]: [272000, 2625.3061224489898],
  [ISongs.ScatterAppear_3]: [276000, 2544.03628117916],
  [ISongs.ScatterWin]: [280000, 4807.981859410404],
  [ISongs.SmallWin]: [286000, 2108.6621315192815],
  [ISongs.SuperWin]: [290000, 4009.795918367331],
  [ISongs.UI_AutoSpin]: [296000, 253.96825396825307],
  [ISongs.UI_BetChange]: [298000, 104.4897959183686],
  [ISongs.UI_ButtonClose]: [300000, 198.82086167802981],
  [ISongs.UI_ButtonHover]: [302000, 114.64852607707599],
  [ISongs.UI_ButtonPress]: [304000, 105.94104308387386],
  [ISongs.UI_MenuOpen]: [306000, 129.16099773241285],
  [ISongs.UI_SpinStart]: [308000, 187.21088435376032],
  [ISongs.UI_SpinStop]: [310000, 85.6235827664591],
  [ISongs.UltraWin]: [312000, 4372.607709750582],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.Background]: 0.4,
  [ISongs.WinPopup]: 0.5,
  [ISongs.BigWin_Loop]: 0.5,
  [ISongs.BigWin_End]: 0.4,
  [ISongs.BigWin]: 0.4,
  [ISongs.MegaWin]: 0.4,
  [ISongs.SmallWin]: 0.25,
  [ISongs.MediumWin]: 0.25,
  [ISongs.HighWin]: 0.25,
  [ISongs.CountUp_Stop]: 0.3,
  [ISongs.CountUp_Loop]: 0.6,
  [ISongs.UI_AutoSpin]: 0.4,
  [ISongs.UI_BetChange]: 0.4,
  [ISongs.UI_ButtonClose]: 0.4,
  [ISongs.UI_ButtonPress]: 0.4,
  [ISongs.UI_ButtonHover]: 0.4,
  [ISongs.UI_MenuOpen]: 0.4,
  [ISongs.UI_SpinStart]: 0.2,
  [ISongs.UI_SpinStop]: 0.2,
  [ISongs.CasinoAmbiance]: 0.4,
  [ISongs.FeatureTrigger]: 0.2,
  [ISongs.ScatterAppear_1]: 0.2,
  [ISongs.ScatterAppear_2]: 0.2,
  [ISongs.ScatterAppear_3]: 0.2,
  [ISongs.ScatterWin]: 0.2,
  [ISongs.UltraWin]: 0.2,
  [ISongs.SuperWin]: 0.2,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
