import type React from 'react';
import BackBtnSvg from './backBtnSvg';
import styles from './button.module.scss';
import CloseBtnSvg from './closeBtnSvg';
import ConfirmBtnSvg from './confirmBtnSvg';
import type { IButtonIntents, PropsButton } from './d';
import HistoryBtnSvg from './historyBtnSvg';
import LobbyBtnSvg from './lobbyBtnSvg';
import SettingsBtnSvg from './settingsBtnSvg';

const buttonIntents: IButtonIntents = {
  confirm: styles['button_confirm'] as string,
  close: styles['button_close'] as string,
  betSettingsBtn: styles['betSettingsBtn'] as string,
  autoPlaySettingBtn: styles['autoPlaySettingBtn'] as string,
};

export const clickButton = (fnOnClick: () => void, disabled: boolean) => (): void | undefined => {
  if (!disabled) {
    return fnOnClick();
  }
  return undefined;
};

export const clickCaptureButton = (fnOnClick: (() => void) | undefined, disabled: boolean) => (): void | undefined => {
  if (!disabled && fnOnClick) {
    return fnOnClick();
  }
  return undefined;
};
export const touchStartButton = (fnOnClick: (() => void) | undefined, disabled: boolean) => (): void | undefined => {
  if (!disabled && fnOnClick) {
    return fnOnClick();
  }
  return undefined;
};
const getClassName = (intent: string, isActive: boolean) => {
  if (!intent) return '';

  return `${buttonIntents[intent as string]} ${isActive ? styles['active'] : ''}`;
};

const Button: React.FC<PropsButton> = (props) => {
  const {
    title = '',
    className = '',
    disabled = false,
    onTouchStart,
    onClickCapture,
    onClick,
    onMouseOver,
    type,
    intent = '',
    isActive = false,
    children,
    ...otherProps
  } = props;
  return (
    <button
      className={`uiBtn ${styles['btn']} ${isActive ? `uiBtn_active ${styles['active']}` : ''} ${getClassName(
        intent,
        isActive,
      )} ${className}`}
      type={type || 'button'}
      onClick={clickButton(onClick, disabled)}
      onTouchStart={touchStartButton(onTouchStart, disabled)}
      onClickCapture={clickCaptureButton(onClickCapture, disabled)}
      onMouseEnter={onMouseOver ? clickButton(onMouseOver, disabled) : undefined}
      disabled={disabled}
      {...otherProps}
    >
      {props.intent === 'close' ? (
        <CloseBtnSvg />
      ) : props.intent === 'confirm' ? (
        <ConfirmBtnSvg />
      ) : props.intent === 'lobby' ? (
        <LobbyBtnSvg />
      ) : props.intent === 'history' ? (
        <HistoryBtnSvg />
      ) : props.intent === 'settings' ? (
        <SettingsBtnSvg />
      ) : props.intent === 'back' ? (
        <BackBtnSvg />
      ) : (
        children || title
      )}
    </button>
  );
};

export default Button;
