import type React from 'react';

const SoundSvg = () => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2339_26622)">
        <path
          d="M13.0858 19.0001H9.5C8.94772 19.0001 8.5 19.4478 8.5 20.0001V28.0001C8.5 28.5523 8.94772 29.0001 9.5 29.0001H13.1058C13.3591 29.0001 13.603 29.0962 13.7882 29.269L20.7118 35.7311C20.897 35.9039 21.1409 36.0001 21.3942 36.0001H23.5C24.0523 36.0001 24.5 35.5523 24.5 35.0001V13.0001C24.5 12.4478 24.0523 12.0001 23.5 12.0001H20.9142C20.649 12.0001 20.3946 12.1054 20.2071 12.293L13.7929 18.7072C13.6054 18.8947 13.351 19.0001 13.0858 19.0001Z"
          fill="white"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.5 34C38.0228 34 42.5 29.5228 42.5 24C42.5 18.4772 38.0228 14 32.5 14"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M32.5 28C34.7091 28 36.5 26.2091 36.5 24C36.5 21.7909 34.7091 20 32.5 20"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2339_26622">
          <rect width="48" height="48" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SoundSvg;
