import type React from 'react';

const AutoplayOnSvg = () => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_ii_2339_26610)">
        <rect x="0.5" width="48" height="48" rx="16" fill="#FF3D00" />
      </g>
      <path
        d="M39.652 30.6432C40.0437 31.2699 40.9563 31.2699 41.348 30.6432L44.5438 25.53C44.96 24.864 44.4812 24 43.6958 24H37.3042C36.5188 24 36.04 24.864 36.4562 25.53L39.652 30.6432Z"
        fill="white"
      />
      <path
        d="M9.348 17.3568C8.95633 16.7301 8.04367 16.7301 7.652 17.3568L4.45625 22.47C4.03997 23.136 4.51881 24 5.30425 24L11.6958 24C12.4812 24 12.96 23.136 12.5438 22.47L9.348 17.3568Z"
        fill="white"
      />
      <path d="M18.5 30L24.5 16L30.5 30" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.5 26H28" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M40.5 24C40.5 15.1634 33.3366 8 24.5 8C21.5857 8 18.8534 8.77915 16.5 10.1405M8.5 24C8.5 32.8366 15.6634 40 24.5 40C27.4143 40 30.1466 39.2208 32.5 37.8595"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_ii_2339_26610"
          x="0.5"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2339_26610" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_innerShadow_2339_26610" result="effect2_innerShadow_2339_26610" />
        </filter>
      </defs>
    </svg>
  );
};

export default AutoplayOnSvg;
