import type React from 'react';

const SpinSvg = () => {
  return (
    <svg width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_ii_2339_26603)">
        <circle cx="48.5" cy="48" r="48" fill="#FFA800" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4802 20.9263C42.3525 11.9322 60.1949 13.1748 71.6795 24.6594C78.1227 31.1026 81.3415 39.547 81.344 47.9849H88.184C89.864 47.9849 90.794 49.9249 89.744 51.2349L77.904 66.0349C77.104 67.0349 75.584 67.0349 74.784 66.0349L62.944 51.2349C61.894 49.9249 62.824 47.9849 64.504 47.9849H71.344C71.3415 42.0895 69.1002 36.2222 64.6085 31.7305C56.6131 23.7351 44.1755 22.8577 35.2078 29.1236C32.9442 30.7052 29.827 30.1523 28.2454 27.8887C26.6637 25.6251 27.2166 22.5079 29.4802 20.9263ZM18.784 29.945L6.94396 44.745C5.89396 46.055 6.82396 47.995 8.50396 47.995L15.344 47.9949C15.344 56.4362 18.5627 64.8847 25.0085 71.3305C36.4931 82.8151 54.3355 84.0577 67.2078 75.0636C69.4714 73.482 70.0242 70.3648 68.4426 68.1012C66.861 65.8376 63.7438 65.2847 61.4802 66.8663C52.5125 73.1322 40.0749 72.2548 32.0795 64.2594C27.5853 59.7652 25.344 53.8937 25.344 47.9949L32.184 47.995C33.864 47.995 34.794 46.055 33.744 44.745L21.904 29.945C21.104 28.945 19.584 28.945 18.784 29.945Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_ii_2339_26603"
          x="0.5"
          y="0"
          width="96"
          height="96"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2339_26603" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_innerShadow_2339_26603" result="effect2_innerShadow_2339_26603" />
        </filter>
      </defs>
    </svg>
  );
};

export default SpinSvg;
