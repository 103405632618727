import type React from 'react';

const StartSvg = () => {
  return (
    <svg width="116" height="60" viewBox="0 0 116 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="116" height="60" rx="18" fill="#464646" />
      <g filter="url(#filter0_ii_101_27227)">
        <rect x="2" y="2" width="112" height="56" rx="16" fill="#FFA800" />
      </g>
      <path
        d="M74.9165 24.9481C78.8054 27.1934 78.8054 32.8065 74.9165 35.0517L53.9165 47.1761C50.0276 49.4213 45.1665 46.6148 45.1665 42.1243L45.1665 17.8756C45.1665 13.3851 50.0276 10.5785 53.9165 12.8237L74.9165 24.9481Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_ii_101_27227"
          x="2"
          y="2"
          width="112"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_101_27227" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_innerShadow_101_27227" result="effect2_innerShadow_101_27227" />
        </filter>
      </defs>
    </svg>
  );
};

export default StartSvg;
