import { Spine } from 'pixi-spine';
import { Container, Loader, Sprite, Texture } from 'pixi.js';
import { MAPPED_BLURRED_SYMBOLS, MAPPED_SYMBOLS, MAPPED_SYMBOLS_LAND_ANIMATIONS, SlotId } from '../../config';
import { getFromMappedSymbol, nextTick } from '../../gameUtils';
import { EventTypes } from '../../global.d';
import { eventManager, SLOT_HEIGHT, SLOT_SCALE, SLOT_WIDTH, SLOTS_PER_REEL_AMOUNT } from '../config';

class Slot extends Container {
  public id: number;

  public slotId: SlotId;

  private reelId: number | undefined;

  public textureSlotId: SlotId;

  public slot: Sprite;

  private landAnimation!: Spine;

  constructor(id: number, slotId: SlotId, reelId?: number) {
    super();

    this.id = id;
    this.slotId = slotId;
    this.reelId = reelId;
    this.textureSlotId = slotId;
    this.width = SLOT_WIDTH * SLOT_SCALE;
    this.height = SLOT_HEIGHT * SLOT_SCALE;
    this.y = (SLOTS_PER_REEL_AMOUNT - id - 0.5) * SLOT_HEIGHT;
    this.x = SLOT_WIDTH / 2;
    this.zIndex = 1;
    this.slotId = slotId;
    this.slot = this.initSlot();
    this.addChild(this.slot);
  }

  private initSlot(): Sprite {
    const slot = new Sprite(Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, this.slotId)));
    slot.anchor.set(0.5, 0.5);
    slot.scale.set(SLOT_SCALE);
    return slot;
  }

  public changeTexture(slotId: SlotId): void {
    this.slot.texture = Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId));
    this.textureSlotId = slotId;
  }

  public changeSlot(slotId: SlotId): void {
    this.changeTexture(slotId);
    this.slotId = slotId;
  }

  public toggleBlur(blur: boolean): void {
    if (blur) {
      this.slot.texture = Texture.from(MAPPED_BLURRED_SYMBOLS[this.textureSlotId].default);
    } else {
      this.slot.texture = Texture.from(MAPPED_SYMBOLS[this.textureSlotId].default);
    }
  }

  public onSlotStopped(): void {
    // @TODO ADD add sound/animation on slot stop
    this.playLandAnim();
  }

  private playLandAnim(): void {
    if (MAPPED_SYMBOLS_LAND_ANIMATIONS[this.slotId]) {
      const animationSrc = MAPPED_SYMBOLS_LAND_ANIMATIONS[this.slotId]?.src;
      const animationName = MAPPED_SYMBOLS_LAND_ANIMATIONS[this.slotId]?.animation;
      this.landAnimation = new Spine(Loader.shared.resources![animationSrc!]!.spineData!);

      this.landAnimation.state.addListener({
        start: () => {
          nextTick(() => {
            this.slot.visible = false;
          });
        },
        complete: () => {
          nextTick(() => {
            if (this.landAnimation.state) this.landAnimation.destroy();
            this.slot.visible = true;
            eventManager.emit(EventTypes.REEL_LANDED_ANIMATION_PLAYED);
          });
        },
      });
      this.landAnimation.state.setAnimation(0, animationName!, false);
      this.addChild(this.landAnimation);
    } else {
      eventManager.emit(EventTypes.REEL_LANDED_ANIMATION_PLAYED);
    }
  }
}

export default Slot;
