import { calcPercentage, isRegularMode } from '../../gameUtils';
import { EventTypes, GameMode, PopupOpeningTypes } from '../../global.d';
import {
  // setIsOpenAutoplayPopup,
  // setIsOpenBetSettingsPopup,
  // setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsPopupOpened,
  setIsPopupOpeningInProgress,
} from '../../gql/cache';
import { Logic } from '../../logic';
import { eventManager } from '../config';
import { UiButton } from '../ui/uiButton';

class InfoBtn extends UiButton {
  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  constructor() {
    super('info');
    this.interactive = !this.isDisabled;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
  };

  private handleClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.INFO_POPUP);

      setIsOpenInfoPopup(!setIsOpenInfoPopup());
      // setIsOpenHistoryPopup(false);
      // setIsOpenAutoplayPopup(false);
      // setIsOpenBetSettingsPopup(false);
      setIsPopupOpened(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private handleDisable = (): void => {
    this.setDisable(
      !isRegularMode(Logic.the.controller.gameMode) ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isSlotBusy ||
        setIsPopupOpened(),
    );
  };

  public override handlePosition(): void {
    this.btn.anchor.set(0, 0);
    let size = calcPercentage(this.applicationSize.width, 7.5);
    const gap = calcPercentage(this.applicationSize.width, 1.25);
    let x = size + gap * 2;
    const y = gap;

    this.setSize(size);

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 7.5);
      x = size + gap * 2;
      this.setSize(size);
    }

    this.x = x;
    this.y = y;
  }
}

export default InfoBtn;
