import type React from 'react';

const LobbySvg = () => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2340_27889)">
        <path
          d="M36.5 38H12.5V26H8.5L24.5 10L40.5 26H36.5V38Z"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2340_27889">
          <rect width="48" height="48" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LobbySvg;
