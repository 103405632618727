import { isMobile, Sprite, Texture } from 'pixi.js';
import { ResourceTypes } from '../../../resources.d';
import { ViewContainer } from '../../components/ViewContainer';
import { FEATURE_POPUP_HEIGHT, FEATURE_POPUP_WIDTH } from '../../config';

class BuyFeaturePopupBackgroundStatic extends ViewContainer {
  private popupBg: Sprite;

  constructor() {
    super();
    this.popupBg = this.initPopupBg();
    this.init();
  }

  private init(): void {
    this.addChild(this.popupBg);
  }

  private initPopupBg(): Sprite {
    const popupBg = new Sprite(Texture.from(ResourceTypes.betMinusBtnHover));
    popupBg.width = isMobile.any ? 1150 : FEATURE_POPUP_WIDTH;
    popupBg.height = isMobile.any ? 925 : FEATURE_POPUP_HEIGHT;
    popupBg.x = isMobile.any ? 40 : 90;
    popupBg.y = isMobile.any ? 45 : 40;

    return popupBg;
  }
}

export default BuyFeaturePopupBackgroundStatic;
