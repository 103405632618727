import { gql } from '@apollo/client';

export const authGql = gql`
  mutation Auth($input: AuthInput!) {
    auth(input: $input) {
      sessionId
    }
  }
`;

export const versionGql = gql`
  mutation Version {
    version {
      version
      time
    }
  }
`;

export const placeBetGql = gql`
  mutation PlaceBet($input: PlaceBetInput!) {
    placeBet(input: $input) {
      bet {
        id
        coinAmount
        coinValue
        slotId
        reelSet {
          id
          layout
          type
        }
        lineSet {
          id
          lines
          coinAmountMultiplier
        }
        userBonus {
          bonus {
            reelSetId
          }
        }
        result {
          reelPositions
          winCoinAmount
        }
        data
        createdAt
        updatedAt
      }
      paylines {
        winPositions
        amount
        lineId
      }
      balance {
        placed {
          amount
          currency
        }
        settled {
          amount
          currency
        }
      }
    }
  }
`;

export const buyBonusGql = gql`
  mutation buyBonus($input: BuyBonusesInput!) {
    buyBonus(input: $input) {
      id
      bonusId
      bonus {
        id
        reelSetId
        coinAmount
        rounds
        data
        type
      }
      status
      coinValue
      data
      balance {
        amount
        currency
      }
    }
  }
`;
