import type React from 'react';

const HistoryBtnSvg = () => {
  return (
    <svg
      className="svg-image"
      width="97"
      height="96"
      viewBox="0 0 97 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="48.5" cy="48" r="32" stroke="white" strokeWidth="6" />
      <path d="M46.5 34V50H62.5" stroke="white" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default HistoryBtnSvg;
