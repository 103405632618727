import { States } from '../config';
import { Logic } from '../index';
import { State } from './State';

export class Jingle extends State {
  public name: States = States.JINGLE;

  public nodes: Map<States, State> = new Map();

  public static the = new Jingle();

  private constructor() {
    super();
  }

  public enterState(prevState: States): void {
    Logic.the.controller.enterJingleState(prevState);
  }

  public exitState(nextState: States): void {
    Logic.the.controller.exitJingleState(nextState);
  }
}
