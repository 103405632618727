import { TextStyle } from 'pixi.js';
import { Variables } from '../../config';

export const bottomContainerTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fill: '#FFFFFF',
  fontSize: 28,
  lineHeight: 38,
  stroke: '#464646',
  fontWeight: '700',
  strokeThickness: 8,
  lineJoin: 'round',
  trim: true,
});

export const bottomContainerValueStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fill: '#FFF7AE',
  fontSize: 40,
  fontWeight: '900',
  lineHeight: 54,
  stroke: '#464646',
  strokeThickness: 8,
  lineJoin: 'round',
  trim: true,
});
